<template>
  <div class="table-container">
    <v-data-table
      ref="datatable"
      class="data-table no-stack"
      :headers="headers"
      :items="records"
      item-key="ts"
      :item-class="getRowClass"
      @click:row="handleRowClick"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "Record-List",
  components: {},
  props: {
    records: Array,
    fields: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleRowClick(record, index) {
      console.log(index);
      this.$emit("record_selected", index);
    },
    getRowClass(item) {
      if (item.invalid) {
        return "red";
      }
    },
  },
  computed: {
    headers() {
      return this.fields
        .filter(function (el) {
          return el["label"] != null;
        })
        .map((item) => ({ value: item.key, text: item.label }));
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto; /* Ensure horizontal scroll */
}

.data-table {
  min-width: 1000px; /* Force a minimum width that ensures horizontal scrolling */
  table-layout: fixed; /* Fix the table layout */
}

.no-stack {
  display: block !important; /* Ensure the table remains in block layout */
  white-space: nowrap; /* Prevent wrapping */
}

v-data-table__wrapper {
  overflow-x: auto !important; /* Ensure the wrapper allows horizontal scroll */
}

/* Disable Vuetify's mobile/stacked view */
v-data-table {
  display: table !important; /* Force the table to behave like a regular table */
}

v-data-table-header {
  display: table-header-group !important; /* Ensure header behaves like table header */
}

v-data-table__mobile {
  display: none !important; /* Disable mobile layout behavior */
}
.invalid-row {
  background-color: red !important;
  border-color: red !important;
}
</style>
